import ScrollMagic from 'scrollmagic';
import 'ScrollMagicAddIndicators';

export default {

	init() {

		var container = document.querySelector('.locationMovie');

		if( container ) {

			container.querySelector('video').load();


			// -- scrollMagic -- // 

				// var controller = new ScrollMagic.Controller();

				// var scene = new ScrollMagic.Scene({triggerElement: container, triggerHook: 0.75 })
				// 			// trigger animation by adding a css class
				// 			.addIndicators({name: "- locationMovie "}) // add indicators (requires plugin)
				// 			.addTo(controller)
				// 			.offset(container.offsetHeight/2)
				// 			.on("enter", function (e) {

				// 				container.classList.add('startVideo');
				// 				container.querySelector('video').play();

				// 				scene.destroy();
				// 				controller.destroy();
				// 				controller = null;

				// 			});

			// -- END -- //



			// -- MouseEnter -- //

				// container.addEventListener('mouseenter', function() {

				// 	container.classList.add('startVideo');
				// 	container.querySelector('video').play();

				// });

			// -- END -- //

		}

	},
	finalize(){

	}

}