export default {

	init() {


		if( document.querySelector('main.roomSwitch') ) {

			function openPSfromRoomCols( box ) {

				var photoSwipe = box.querySelector('.gallerySlider--animate');
				var openPS = box.querySelector('.roomCols .roomCols__col .simpleLink--openPhotoSwipe');

				if( photoSwipe ) {

					openPS.addEventListener('click', function() {

						box.querySelector('.gallerySlider__photoSwipe').click();

					});

				}

			}

			var layouts = document.querySelectorAll('.types__single');

			layouts.forEach( function(box,index) {

				openPSfromRoomCols( box );

			});

		} else if( document.querySelector('main.room') ) {

			// -- Open PhotoSwipe -- //

				// animowana galeria zdjęć || na szczycie strony
				//						   \/
				var container = document.querySelector('.gallerySlider--animate');

				if( container ) {

					var openPS = document.querySelector('.roomCols .roomCols__col .simpleLink--openPhotoSwipe');

					openPS.addEventListener('click', function() {

						container.querySelector('.gallerySlider__photoSwipe').click();

					});

				}

			// -- END -- //

		}

	},
	finalize() {

	}

}