import sal from 'sal.js';

export default {
	init() {

		// -- sal.js

			function salJs() {

				var salSelector = document.querySelectorAll('[data-sal]');

				// console.log(salSelector);

				if( salSelector.length > 0 ) {

					salSelector.forEach( function(item,index) {

						// if( !item.getAttribute('data-sal-duration') ) {
							item.setAttribute('data-sal-duration',1000);
						// }

					});

					const salObject = sal({
						threshold: 0.15,
					});

					salObject.enable();
					// console.log('t1');

				}

			}

			salJs();

		// -- END 

	},finalize(){

	}
}