export default {
	init() {

		function RenderGoogleMap( box ) {

			var markerIconUrl = window.location.origin +'/wp-content/themes/oliwski/img/svg/marker_location.svg';

			var lati = box.getAttribute('data-lati');
			var long = box.getAttribute('data-long');
			var marker_title = 'Hotel Oliwski';

			var map;
			    
		    var mapOptions = {

		        zoom: 14.4,
		        center: new google.maps.LatLng(lati, long), // New York
		        mapTypeControl: false,
		        streetViewControl: false,
		        fullscreenControl : false,
		        styles: [
				    {
				        "featureType": "administrative",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#444444"
				            }
				        ]
				    },
				    {
				        "featureType": "landscape",
				        "elementType": "all",
				        "stylers": [
				            {
				                "color": "#f2f2f2"
				            }
				        ]
				    },
				    {
				        "featureType": "poi",
				        "elementType": "all",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "road",
				        "elementType": "all",
				        "stylers": [
				            {
				                "saturation": -100
				            },
				            {
				                "lightness": 45
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "all",
				        "stylers": [
				            {
				                "visibility": "simplified"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "labels.icon",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "transit",
				        "elementType": "all",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "water",
				        "elementType": "all",
				        "stylers": [
				            {
				                "color": "#46bcec"
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    }
				]

		    };
		    
		    var mapElement = box.querySelector('.googleMap__content');

		    map = new google.maps.Map(mapElement, mapOptions);

		    var mainMarkerIcon = { 
		    	url: markerIconUrl,
		    	scaledSize: new google.maps.Size(45, 73), // scaled size
			    origin: new google.maps.Point(0,0), // origin
			    anchor: new google.maps.Point(22, 60) // anchor
		    };

		    var marker = new google.maps.Marker({
		        position: new google.maps.LatLng(lati, long),
		        title: marker_title,
		        map: map,
		        icon : mainMarkerIcon,
		    });
			
		    google.maps.event.addListener(marker, 'click', function() {
		    	
		    	window.open( box.getAttribute('data-url') , '_blank');

			});

		}

		var container = document.querySelectorAll('section.googleMap');

		if( container.length > 0 ) {

			container.forEach( function( box, index ) {	

				RenderGoogleMap( box );

			});

		}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
