export default {
	init() {
		
		function ImagesSlider( container ) {

			var images = container.querySelectorAll('.imagesSlider__image');
			var imagesMax = images.length;

			var imagesCurrent = 0;

			var buttonNext = container.querySelector('.imagesSlider__button--next');
			var buttonPrev = container.querySelector('.imagesSlider__button--prev');


			// -- Suport -- //

				function NextSlide() {

					imagesCurrent += 1;

					if( imagesCurrent >= imagesMax ) {

						imagesCurrent = 0;

					}

					images.forEach( function( image, index ){

						if( index == imagesCurrent ) {

							image.classList.add('actual');

						} else {

							image.classList.remove('actual');

						}

					});

				}

				function PrevSlide() {

					imagesCurrent -= 1;

					if( imagesCurrent < 0 ) {

						imagesCurrent = imagesMax-1;

					}

					images.forEach( function( image, index ){

						if( index == imagesCurrent ) {

							image.classList.add('actual');

						} else {

							image.classList.remove('actual');

						}

					});

				}

			// -- END -- // 



			// -- Loop -- //

				var interval;
				var intervalTime = 2000;

				function LoopStart() {

					interval = setInterval(
						function() {
							NextSlide();
						},
						intervalTime
					);
				}

				function LoopEnd(){

					clearInterval(interval);

				}

			// -- END -- // 



			// -- Buttons -- // 

				buttonNext.addEventListener('click', function() {

					LoopEnd();
					NextSlide();
					LoopStart();

				});

				buttonPrev.addEventListener('click', function() {

					LoopEnd();
					PrevSlide();
					LoopStart();

				});

			// -- END -- //


			LoopStart();

		}

		var container = document.querySelectorAll('.imagesSlider');

		if( container.length > 0 ) {

			container.forEach( function( box, index ) {

				ImagesSlider(box);

			});

		}

		
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
