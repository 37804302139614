import Subpage from '../../Layout/Subpage.js';
import LazyLoad from '../../../common/lazyLoad/LazyLoad.js';

export default {

	init() {

		function OnPopupContainerClick() {

			var popupContainer = document.querySelector('.imagePopup');

			popupContainer.addEventListener('click', function() {

				Subpage.setCookie(cookieName , cookieValue , cookieLive);
				popupContainer.classList.remove('active');

				setTimeout(function() {
					popupContainer.remove();
				}, 200);
					

			});

			if( popupContainer.querySelector('a') ) {

				popupContainer.querySelector('a').addEventListener('click', function(e) {

					e.stopPropagation();

				});		

			}

		}

		function ShowPopupDiv() {

			var url = document.querySelector('footer').getAttribute('data-endpoint');

			var req = new XMLHttpRequest();

			req.onreadystatechange = function(){

				if( req.readyState==4 && req.status==200 ) {
					
					if( req.responseText ) {

						document.querySelector('body').insertAdjacentHTML('beforeend', req.responseText);

						setTimeout(
							function(){ 
								document.querySelector('.imagePopup').classList.add('active');
							}, 
							1000
						);

						LazyLoad.init();
						
						OnPopupContainerClick();

					}

				}

			}

			req.open( 'POST', url, true );
			req.setRequestHeader( 'Content-type', 'application/x-www-form-urlencoded' );
			req.send( 'action=CookiePopup' );

		}

		var cookieName = 'cookie-popup';
		var cookieValue = '1';
		var cookieLive = 1; //in day
		//showCookieDiv() remember to change url do php file (line 2)

		var myCookie = Subpage.checkCookie(cookieName);


		if(myCookie != 1) {
			ShowPopupDiv();
		};


	},
	finalize() {

	}

}