import ScrollMagic from 'scrollmagic';
import 'ScrollMagicAddIndicators';

export default {
	init() {

		var container = document.querySelectorAll('.imageScrollEfekt');

		if( container.length > 0 ) {

			function imageScrollEfekt( container ){

				var controller = new ScrollMagic.Controller();
				var image = container.querySelector('.imageScrollEfekt__image');
				var containerHeight = container.offsetHeight;

				var transform;

				// new ScrollMagic.Scene({triggerElement: container, duration: '68%', triggerHook: 0 })
				// 	// trigger animation by adding a css class
				// 	.addIndicators({name: "- imageScrollEfekt "}) // add indicators (requires plugin)
				// 	.addTo(controller)
				// 	.offset(1)
				// 	.on("progress", function (e) {

				// 		if( window.innerWidth > 800 ) {

				// 			transform = containerHeight * e.progress.toFixed(2) * 0.3;
				// 			image.style.transform = 'translateY('+transform+'px)';

				// 		}

				// 	});

				if( image.offsetHeight > container.offsetHeight ) {

					var substract = image.offsetHeight - container.offsetHeight;

					new ScrollMagic.Scene({triggerElement: container, duration: '40%', triggerHook: 0.5 })
						// trigger animation by adding a css class
						.addIndicators({name: "- imageScrollEfekt "}) // add indicators (requires plugin)
						.addTo(controller)
						.offset(0)
						.on("progress", function (e) {

							if( window.innerWidth > 800 && substract > 0 ) {

								if( e.progress < 0.5 ) {

									transform = e.progress * substract * 2 ;
									console.log(transform);
									image.style.transform = 'translateY(-'+transform+'px)';

								} else {

									image.style.transform = 'translateY(-'+substract+'px)';

								}


							} else {

								image.style.transform = 'translateY(0px)';

							}

						}

					);

					window.addEventListener('resize', function(){

						substract = image.offsetHeight - container.offsetHeight;
						console.log(substract);

					});

				} else {

					container.style.height = 'auto';

				}

			}

			container.forEach( function( box, index ) {

				new imageScrollEfekt( box );

			});

		}

	},
	finalize() {

	}
}