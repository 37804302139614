import ScrollMagic from 'scrollmagic';
import 'ScrollMagicAddIndicators';
import $ from 'jquery';

export default {
	init() {
		var t = this;
		// -- scrollMagicDesktopMenu

			function scrollMagicDesktopMenu() {

				var header = document.querySelector('header');

				var main = document.querySelectorAll('main');

				var controller = new ScrollMagic.Controller();

				new ScrollMagic.Scene({triggerElement: main, triggerHook: 0 })
					// trigger animation by adding a css class
					.setClassToggle(header, "header__transform")
					// .on('update', function() {

					// 	if( window.innerWidth > 1024 ) {

					// 		if( header.classList.contains('header__transform') && controller.info('scrollDirection') == 'FORWARD' ) {

					// 			header.classList.add('header__transform--smaller');

					// 		} else if( header.classList.contains('header__transform') && controller.info('scrollDirection') == 'REVERSE' ) {

					// 			header.classList.remove('header__transform--smaller');

					// 		}
							
					// 	}

					// })
					// .addIndicators({name: "- Subpage "}) // add indicators (requires plugin)
					.addTo(controller)
					.offset(1);

				// window.addEventListener('resize', function(event){

				// 	if( window.innerWidth < 1024 && header.classList.contains('header__transform--smaller') ) {

				// 		header.classList.remove('header__transform--smaller');
						
				// 	}
						
				// });

			}

			// scrollMagicDesktopMenu();

		// -- END 



		// -- reservation

			function reservation() {

				var reservationsButton = document.querySelectorAll('.UpperBooking');

				reservationsButton.forEach( function( button,index ) {

					button.addEventListener('click', function(e) {

						e.preventDefault();
						// if( button.getAttribute('href') ) {
							// t.gtag_report_conversion( button.getAttribute('href') );
						// } else {
							// t.gtag_report_conversion();	
						// }
						Booking.Open();
						return false;

					});

				});

			}

			reservation();

		// -- END 



		// -- scrollToTop

			function scrollToTop() {

				var button = document.querySelector('header .header__scrollTop');

				button.addEventListener('click', function(e) {

					e.preventDefault();
					$('html ,body').animate({'scrollTop': 0 }, 'smooth');

				});

			}

			scrollToTop();

		// -- END



		// -- languagesSwitchMobile

			function languagesSwitchMobile() {

				var container = document.querySelector('header .header__col .header__languages');

				container.addEventListener('click', function(){

					if( container.classList.contains('active') ) {

						container.classList.remove('active');

					} else {

						container.classList.add('active');

					}

				});

			}

			languagesSwitchMobile();

		// -- END 



		// -- fakeMenuLink

			function fakeMenuLink() {

				var menus = document.querySelectorAll('header .header__col .nav__menu .withChild');

				menus.forEach( function( menu, index ) {

					menu.querySelector('a.item__single').addEventListener('click', function(e){

						e.preventDefault();

						// if( menu.classList.contains('active') ) {

						// 	menu.classList.remove('active');

						// } else {

						// 	menu.classList.add('active');

						// }

					});

				});


			}

			fakeMenuLink();

		// -- END



		// -- mobileMenu

			function mobileMenu() {

				var hamburger = document.querySelector('header .header__hamburger');
				var mobileMenu = document.querySelector('section.mobileMenu');

				hamburger.addEventListener('click',function(e) {

					e.stopPropagation();

					if( mobileMenu.classList.contains('active') ) {

						hamburger.classList.remove('active');
						mobileMenu.classList.remove('active');

					} else {

						hamburger.classList.add('active');
						mobileMenu.classList.add('active');

					}

				});

				mobileMenu.querySelector('.mobileMenu__content').addEventListener('click',function(e) {

					e.stopPropagation();

				});

				document.addEventListener('click',function(){

					if( mobileMenu.classList.contains('active') ) {

						hamburger.classList.remove('active');
						mobileMenu.classList.remove('active');

					}

				});

			}

			mobileMenu();

		// -- END 


	},
	checkCookie(cname) {

	    var name = cname + "=";
	    var decodedCookie = decodeURIComponent(document.cookie);
	    var ca = decodedCookie.split(';');
	    for(var i = 0; i <ca.length; i++) {
	        var c = ca[i];
	        while (c.charAt(0) == ' ') {
	            c = c.substring(1);
	        }
	        if (c.indexOf(name) == 0) {
	            return c.substring(name.length, c.length);
	        }
	    }
	    return "";

	},
	setCookie(cname, cvalue, exdays) {
	    var d = new Date();
	    d.setTime(d.getTime() + (exdays*24*60*60*1000));
	    var expires = "expires="+ d.toUTCString();
	    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	},
	gtag_report_conversion(url) { // function
		var callback = function () {
			if (typeof(url) != 'undefined') {
				window.location = url;
			}
		};
		gtag('event', 'conversion', {
			'send_to': 'AW-304892941/bsUUCPPZ-_ECEI2YsZEB',
			'event_callback': callback
		});
		return false;
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
