import lozad from 'lozad';
import Hammer from 'hammerjs/hammer';
// import TweenMax from 'gsap/TweenMax';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default {
	init() {

		var container = document.querySelectorAll('.gallerySlider--animate');

		if( container.length > 0 ) {

			function gallerySlider( container ) {

				var imagesContainer = container.querySelector('.gallerySlider__container');
				
				// -- Global -- //

					var images = container.querySelectorAll('.container__image'); // images array
					var imagesCount = images.length; // images length 

					var imageIndex = 1; // index of current image

					var imagesWidth ; // array with images width
					var imagesCenter ; // array with image center point
					var imagesBreakpoint ; // array with images breakpoint( where start )

					var windowWidth = window.innerWidth;

					// position variable
					var currentPosition = 0;
					var temp = 0;

					var buttonNext = container.querySelector('.gallerySlider__button--next');
					var buttonPrev = container.querySelector('.gallerySlider__button--prev');


					function setActiveImage( imageIndex ) {

						images.forEach( function( image , index ) {

							if( imageIndex == index ) {

								image.classList.add('active');

							} else {

								image.classList.remove('active');

							}

						});

					}

				// -- END Global -- //



				// -- initFunction -- //

					var imageStyle;
					var imageWidth;
					var margin;
					function getImagesWidth() {

						images.forEach( function(image, index) {

							imageStyle = window.getComputedStyle(image);
							margin = parseFloat( imageStyle.marginLeft.replace('px', '') );
							
							imageWidth = parseFloat(image.getBoundingClientRect().width);

							imagesWidth.push( imageWidth + margin + margin );

						});

					}
					

					function getImageCenter() {

						var value;

						images.forEach( function(cell , index) {

							value = 0;

							if( index == 0 ) {

								value = (windowWidth/2) - imagesWidth[index] / 2;

							} else {

								value = imagesCenter[index-1] - ( (imagesWidth[index-1]/2) + (imagesWidth[index]/2));
								

							}

							imagesCenter.push( value );

						});

					}
					

					function setPositionByIndex( index ) {

						setActiveImage(index);

						imagesContainer.style.transform = "translate3d( "+ imagesCenter[index] +"px, 0px, 0px )";

						currentPosition = imagesCenter[index];

						if( index == 0 ) {

							buttonPrev.classList.add('disable');

						} else if( index == imagesCount-1 ) {

							buttonNext.classList.add('disable');

						} else {

							buttonPrev.classList.remove('disable');
							buttonNext.classList.remove('disable');

						}

					}


					function setPositionByPX( px ) {

						imagesContainer.style.transform = "translate3d( "+ px +"px, 0px, 0px )";

					}


					function calcImagesBreakpoint() {

						var value;

						images.forEach( function(cell , index) {

							value = 0;

							images.forEach( function(cell2, index2) {
								
								if( index2 < index ) {

									value = value + imagesWidth[index2];

								} 

							});

							value = (windowWidth /2 - value);

							imagesBreakpoint.push(value);

						});

					}
					
				// -- END initFunction -- //



				// -- actionButton -- //

					buttonNext.addEventListener('click' , function(){

						if( imageIndex < imagesCount-1 ) {

							imageIndex += 1;

							setPositionByIndex(imageIndex);

						}

					});

					buttonPrev.addEventListener('click' , function(){

						if( imageIndex > 0 ) {

							imageIndex -= 1;

							setPositionByIndex(imageIndex);

						}

					});

				// -- END  actionButton -- //

				

				// -- Hammer -- //

					var mc = Hammer( 
						container ,
						{
							inputClass : Hammer.TouchMouseInput,
							recognizers: [
						        [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }]
						    ]
						}
					);

					mc.on('panstart', function(event) {

						imagesContainer.classList.add('noTransition');

					});

					mc.on('panleft', function( event ) {

						temp = currentPosition + event.deltaX; // calc actual position

						images.forEach( function( image, index ) {

							if( imagesBreakpoint[index] > temp ) {

								imageIndex = index;					

							} else if( temp > imagesBreakpoint[0] ) {

								imageIndex = 0;

							}

						});

						setActiveImage( imageIndex );
						setPositionByPX( temp );

					});

					mc.on('panright',function(event) {

						temp = currentPosition + event.deltaX; // calc actual position

						images.forEach( function( image, index ) {

							if( imagesBreakpoint[index] > temp ) {

								imageIndex = index;					

							} else if( temp > imagesBreakpoint[0] ) {

								imageIndex = 0;	

							}

						});

						setActiveImage( imageIndex );
						setPositionByPX( temp );

					});

					mc.on( 'panend', function( event ) {

						imagesContainer.classList.remove('noTransition');

						setPositionByIndex(imageIndex);

					});

				// -- END Hammer -- //
				


				// -- Init && Resize !! -- //

					function Init() {

						imagesWidth = new Array(); // array with images width
						imagesCenter = new Array(); // array with image center point
						imagesBreakpoint = new Array();

						getImagesWidth();
						getImageCenter();
						calcImagesBreakpoint();
						setPositionByIndex(imageIndex);

					}

					window.addEventListener('resize', function(event){

						windowWidth = window.innerWidth;
						Init();
							
					});

				// -- END Init && Resize !! -- //

				
				
				// -- LazyAsset load images -- //

					var pictureObserverAfterLoadDOM;
					var lozadImages = container.querySelectorAll('.lazy-load-slider');
					window.addEventListener("load", function () {

						//lazy-load-ar - after DOM ready (doładowywanie assetów wraz ze scrollem dopiero po wyrenderowaniu strony)
						pictureObserverAfterLoadDOM = lozad('.lazy-load-slider', {
						    threshold: 0,
						    loaded: function(el) {
			       				
						    	Init();


			   				}
						})
						pictureObserverAfterLoadDOM.observe();
						
						lozadImages.forEach( function( image,index ) {

							pictureObserverAfterLoadDOM.triggerLoad( image );

						});

					});

				// -- END LazyAsset load images -- //


				// -- PhotoSwipe -- //

					var buttonPhotoSwipe = container.closest('.gallerySlider').querySelector('.gallerySlider__photoSwipe');

					var pswp = document.querySelectorAll('.pswp')[0]; // photoSwipe mask
					var items; // photoSwipe images
					var options = Array(); // photoSwipe options
					var photoSwipe; // photoSwipe object

					function PSInit( index ) {

						items = Array();

						options.index = parseInt(index, 10);
						options.zoomEl = true;
						options.shareEl = false;

						var item; 
						var image;
						var image_url;
						var image_dimention;
						var image_width;
						var image_height;

						images.forEach( function( image , index2 ) {

							image_url = image.getAttribute('data-url');
							image_dimention = image.getAttribute('data-size').split('x');
							image_width = image_dimention[0];
							image_height = image_dimention[1];

							item = {

								src: image_url,
			                    w: image_width,
			                    h: image_height

							};

							items.push( item );

						});

						photoSwipe = new PhotoSwipe( pswp, PhotoSwipeUI_Default, items, options);

					}

					PSInit();


					buttonPhotoSwipe.addEventListener('click', function() {

						PSInit( imageIndex );
						photoSwipe.init();

					});

				// -- END -- //

			}

			container.forEach( function( box, index )  {
					
				new gallerySlider( box );

			});

		} 
		//else {

			// Nie pamiętam po co to było, ale po coś kiedyś było ( niech jeszcze trochę pozostanie )
			// var pictureObserverAfterLoadDOM;
			// var lozadImages = container.querySelectorAll('.lazy-load-slider');
			// window.addEventListener("load", function () {

			// 	//lazy-load-ar - after DOM ready (doładowywanie assetów wraz ze scrollem dopiero po wyrenderowaniu strony)
			// 	pictureObserverAfterLoadDOM = lozad('.lazy-load-slider', {
			// 	    threshold: 0,

			// 	})
			// 	pictureObserverAfterLoadDOM.observe();

			// 	lozadImages.forEach( function( image,index ) {

			// 		pictureObserverAfterLoadDOM.triggerLoad( image );

			// 	});

			// });

		//}
    
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
