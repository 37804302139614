import TweenMax from 'gsap/TweenMax';

export default {
	init() {

		function InstagramCarusel( container ) {

			var galleryTimeForSlide = 6;
			var galleryAnimeTime = 0;
			var animation = null;

			var gallery = container.querySelector('.instagram__carusel');
			var galleryWidth = gallery.offsetWidth;
			var gallerySingle = gallery.querySelectorAll('.carusel__single');

			
			function moveGalleryPhotos(animeTime) {

				animation = TweenMax.to(gallery, animeTime, { x: - galleryWidth, force3D: true, ease: Power0.easeNone, onComplete:function() {

					TweenMax.to(gallery, 0, {xPercent: 0, x: window.innerWidth, force3D: true, onComplete:function() {
						
						animeTime = gallerySingle.length * galleryTimeForSlide;

						moveGalleryPhotos(animeTime);

					}});

				}});

			}

			moveGalleryPhotos( gallerySingle.length * galleryTimeForSlide );

			gallery.addEventListener('mouseenter',function(){

				animation.pause();

			});

			gallery.addEventListener('mouseleave', function() {

				animation.resume();

			});

		}

		function InstagramGetImages( container ){

			var url = container.getAttribute('data-url')+'wp-content/themes/oliwski/src/Sections/Instagram/GetInstagramImage.php';
			var schema = container.querySelector('.carusel__single--schema');
			var clone;
			var response;
			var tempHeight = 0;

			var req = new XMLHttpRequest();

			req.onreadystatechange = function(){

				if ( req.readyState==4 && req.status==200 && req.responseText ) {

					response = JSON.parse(req.responseText);

					// console.log(response);

					response.forEach( function( single, index ) {

						clone = schema.cloneNode(true);
						clone.querySelector('p span').textContent = single.likes.count;
						clone.setAttribute('href', single.link );
						clone.querySelector('img').setAttribute('src', single.images.standard_resolution.url );
						clone.classList.remove('carusel__single--schema');

						container.querySelectorAll('.carusel__single')[container.querySelectorAll('.carusel__single').length-1].insertAdjacentHTML('afterend', clone.outerHTML );

					});
					
					

					container.classList.remove('deactive');
					schema.remove();
					// container.querySelector('.instagram__content').style.height = tempHeight+'px';

					InstagramCarusel( container );
					

				}

			}

			req.open('GET', url, false); 
			req.setRequestHeader("Content-type", "text/json");
			req.send();

		}

		var container = document.querySelector('section.instagram');

		if( container ) {

			InstagramGetImages( container );

		}
    
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
