export default {

	init() {

		var container = document.querySelector( 'main.contact' );

		if( container ) {

			gtag('event', 'conversion', {'send_to': 'AW-304892941/TYFkCKyGy_ICEI2YsZEB'});

			function ContactPageList( container ) {

				var points = container.querySelectorAll('.list__single--close');

				var singleHeight = 0;

				points.forEach( function( single, index ) {

					single.querySelector('.single__title').addEventListener('click',function(){

						singleHeight = 0;

						points.forEach( function( single2,index2 ) {

							single2.querySelector('.single__content').style.height = 0+'px';
							single2.classList.remove('open');

						});

						singleHeight = parseFloat( getComputedStyle( single.querySelector('.content__wysiwyg') ).height.replace('px','') ) + parseFloat( getComputedStyle( single.querySelector('.content__wysiwyg') ).marginBottom.replace('px','') ) ;

						single.querySelector('.single__content').style.height = singleHeight + 'px';
						single.classList.add('open');

					});

				});

				var pointsOpen;
				window.onresize = function() {

					pointsOpen = container.querySelectorAll('.list__single--close.open');

					pointsOpen.forEach( function( single, index ) {

						singleHeight = parseFloat( getComputedStyle( single.querySelector('.content__wysiwyg') ).height.replace('px','') ) + parseFloat( getComputedStyle( single.querySelector('.content__wysiwyg') ).marginBottom.replace('px','') ) ;

						single.querySelector('.single__content').style.height = singleHeight + 'px';
						
					});

				}

			}
			var list = document.querySelector('.contact .col__list');
			if( list ) {
				ContactPageList( list );
			}

		}

	},
	finalize() {

	}

}