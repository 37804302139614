import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default {
	init() {

		function GalleryPhotoSwipe() {

			var images = document.querySelectorAll('.images__single');
			

			// *** INIT *** //

				var pswp = document.querySelectorAll('.pswp')[0]; // photoSwipe mask
				var items; // photoSwipe images
				var options = Array(); // photoSwipe options
				var photoSwipe; // photoSwipe object

				function Init(index) {

					items = Array();

					options.index = parseInt(index, 10);
					options.zoomEl = true;
					options.shareEl = false;

					var item; 
					var image;
					var image_url;
					var image_dimention;
					var image_width;
					var image_height;

					images.forEach( function( image , index2 ) {

						image_url = image.getAttribute('data-url');
						image_dimention = image.getAttribute('data-size').split('x');
						image_width = image_dimention[0];
						image_height = image_dimention[1];

						item = {

							src: image_url,
		                    w: image_width,
		                    h: image_height

						};

						items.push( item );

					});

					photoSwipe = new PhotoSwipe( pswp, PhotoSwipeUI_Default, items, options);

				}

				Init();

			// *** END INIT *** //


			// *** OPEN *** //

				images.forEach( function( image, index ) {

					image.addEventListener('click' , function() {

						Init( index );
						photoSwipe.init();

					});

				});

			// *** OPEN *** ///

		}

		var container = document.querySelectorAll('main.gallery');

		if( container.length !=0  ) {		

			GalleryPhotoSwipe();

		}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};