import Subpage from '../../Layout/Subpage.js';

export default {
	init() {

		function OnCookieContainerClick() {

			var cookieContainer = document.querySelector('.cookie');
			var cookiePrivacyPolicy = cookieContainer.querySelector('a');

			cookieContainer.addEventListener('click', function() {

				Subpage.setCookie(cookieName , cookieValue , cookieLive);
				cookieContainer.classList.remove('active');
				cookieContainer.parentNode.removeChild(cookieContainer);
				
			});

			if( cookiePrivacyPolicy ) {

				cookiePrivacyPolicy.addEventListener('click', function(e){

					e.stopPropagation();

				});
				
			}

		}

		function ShowCookieDiv() {
			var url = document.querySelector('footer').getAttribute('data-endpoint');

			var req = new XMLHttpRequest();

			req.onreadystatechange = function(){
				
				if ( req.readyState==4 && req.status==200 && req.responseText ) {
					
					document.querySelector('body').insertAdjacentHTML('beforeend', req.responseText);

					setTimeout(
						function(){ 
							document.querySelector('.cookie').classList.add('active');
						}, 
						1000
					);

					
					OnCookieContainerClick();

				}

			}

			req.open( 'POST', url, true );
			req.setRequestHeader( 'Content-type', 'application/x-www-form-urlencoded' );
			req.send( 'action=CookieInfo' );
			
		}	

		var cookieName = 'cookie-info';
		var cookieValue = '1';
		var cookieLive = 7; //in day
		//showCookieDiv() remember to change url do php file (line 2)

		var myCookie = Subpage.checkCookie(cookieName);
		
		if(myCookie != 1) {
			ShowCookieDiv();
		};

	

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
