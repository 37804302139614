// import jQuery from 'jquery';
import './style.scss';

import LazyLoad from './lazyLoad/lazyLoad';
import Subpage from '../src/Layout/Subpage';
import Sal from '../src/Other/Sal';

// Components
import GoogleMap from '../src/Components/GoogleMap/GoogleMap';
import Cookie from '../src/Components/Cookie/Cookie';
import ImagePopup from '../src/Components/ImagePopup/ImagePopup';
import ImagesSlider from '../src/Components/ImagesSlider/ImagesSlider';
import ContactForm from '../src/Components/ContactForm/ContactForm';
import ImageScrollEfekt from '../src/Components/ImageScrollEfekt/ImageScrollEfekt';
import LocationMovie from '../src/Components/LocationMovie/LocationMovie';

// Sections
import GallerySlider from '../src/Sections/GallerySlider/GallerySlider';
import Instagram from '../src/Sections/Instagram/Instagram';
import RoomCols from '../src/Sections/RoomCols/RoomCols';
import HeadAlert from '../src/Sections/HeadAlert/HeadAlert';

// pages
import Gallery from '../src/Pages/Gallery/Gallery';
import Contact from '../src/Pages/Contact/Contact';
import RoomSwitch from '../src/Pages/RoomSwitch/RoomSwitch';


LazyLoad.init();

document.addEventListener("DOMContentLoaded", function() {
    
    Subpage.init();
    Sal.init();

    // components
    Cookie.init();
    ImagePopup.init();
    GoogleMap.init();
    ImagesSlider.init();
    ContactForm.init();
    ImageScrollEfekt.init();
    LocationMovie.init();

    //sections
    GallerySlider.init();
    Instagram.init();
    RoomCols.init();
    HeadAlert.init();

    //pages
    Gallery.init();
    Contact.init();    
    RoomSwitch.init();
    
});
