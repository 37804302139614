import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('.HeadAlert');

		if( container ) {

			function headAlertCheckCookie(cname) {

			    var name = cname + "=";
			    var decodedCookie = decodeURIComponent(document.cookie);
			    var ca = decodedCookie.split(';');
			    for(var i = 0; i <ca.length; i++) {
			        var c = ca[i];
			        while (c.charAt(0) == ' ') {
			            c = c.substring(1);
			        }
			        if (c.indexOf(name) == 0) {
			            return c.substring(name.length, c.length);
			        }
			    }
			    return "";
			    
			}

			function headAlertSetCookie(cname, cvalue, exdays) {

			    var d = new Date();
			    d.setTime(d.getTime() + (exdays*24*60*60*1000));
			    var expires = "expires="+ d.toUTCString();
			    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

			}

			function headAlertGetContent() {


				var url = document.querySelector('footer').getAttribute('data-endpoint');

				var req = new XMLHttpRequest();

				req.onreadystatechange = function(){

					if ( req.readyState==4 && req.status==200 ) {
						
						container.querySelector('.headAlert__content').insertAdjacentHTML('beforeend', req.responseText);

						container.style.height = container.querySelector('.headAlert__content').offsetHeight +'px';

					   	$('html, body').animate({ scrollTop: 0 }, 500);

					}

				}

				req.open( 'POST', url, true );
				req.setRequestHeader( 'Content-type', 'application/x-www-form-urlencoded' );
				req.send( 'action=HeadAlertContent' );


			}

			var cookieName = 'komunikat-id';
			var alertID = container.getAttribute('data-id');

			var cookieValue = headAlertCheckCookie(cookieName);

			if( alertID != cookieValue ) {

				headAlertGetContent();

				document.querySelector('.headAlert__close').addEventListener('click', function() {				

					headAlertSetCookie(cookieName , alertID , 1);
					container.style.height = '0px';

					if( document.querySelector('main').classList.contains('homepage') ) {

						if( window.innerWidth > 1024 ) {

							container.style.marginTop = '0px';

						}
						
						document.querySelector('header').classList.remove('header--background');

					}

					setTimeout(
						function() {
							container.remove();
						}
						,400
					);

				});

				window.addEventListener('resize' , function() {

					container.style.height = container.querySelector('.headAlert__content').offsetHeight +'px';

				});

			} else {

				container.remove();
				document.querySelector('header').classList.remove('header--background');

			}

		}

	},
	finalize() {

	}

}