import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('.roomSwitch');

		if( container ) {

			function roomSwitch( container ) {

				var mainSwitch = container.querySelectorAll('.roomSwitch__switches button');
				var sectionSwitch = container.querySelectorAll('.roomSwitch__types .roomCols .col__tabs .tabs__switch button');
				var layouts = container.querySelectorAll('.roomSwitch__types .types__single')

				var tempHeight = 0;
				var tempStyle;
				var currentLayout = 0;

				function changeLayout( i ) {

					currentLayout = i;

					mainSwitch.forEach( function(button, index) {

						if( i == index ) {

							button.classList.add('active');

						} else {

							button.classList.remove('active');

						}

					});

					layouts.forEach( function( layout, index) {

						if( i == index ) {

							layout.classList.add('active');

						} else {

							layout.classList.remove('active');

						}

					});

					sectionSwitch.forEach( function( button, index ) {

						if( button.getAttribute('data-layout') == i ) {

							button.classList.add('active');

						} else {

							button.classList.remove('active');

						}

					});

					setLayoutHeight( i );
					setUrl( i );

				}

				function setLayoutHeight( i ) {

					tempHeight = 0;

					layouts[i].querySelectorAll('section').forEach( function( section,index ) {

						tempStyle = getComputedStyle( section );

						tempHeight += section.offsetHeight + parseFloat( tempStyle.marginBottom.replace('px','') ) + parseFloat( tempStyle.marginTop.replace('px','') );

					});

					container.querySelector('.roomSwitch__types').style.height = tempHeight+'px';

				}

				function setUrl( i ) {

					window.history.replaceState(
						{}, 
						null, 
						container.getAttribute('data-url')+'?'+container.getAttribute('data-var')+'='+mainSwitch[i].getAttribute('data-name')
					);

				}

				if( mainSwitch.length > 0 ) {

					mainSwitch.forEach( function( button, index ) {


						button.addEventListener('click', function() {

							changeLayout(index);

						});


					});

				}

				if( sectionSwitch.length > 0 ) {

					sectionSwitch.forEach( function( button, index ) {

						button.addEventListener('click', function(){

							changeLayout( button.getAttribute('data-layout') );

							setTimeout( 
								function(){

									$('html, body').animate({
									    scrollTop: 0
									}, 'smooth');

								},
								301 
							);

						});

					});

				}

				window.addEventListener('resize', function() {

					setLayoutHeight( currentLayout );

				});

			}

			roomSwitch( container );

		}

	}

}